import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "../pohrby/LinkButton";
import CallButton from "../pohrby/CallButton";
import OverenaFirma from "../pohrby/OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import Zpatky from "../../assets/img/zpatky.svg";
import Telefon from "../../assets/img/telefon.svg";
import ditevsrdci from "../../assets/img/ditevsrdci.svg";
import gbPomaha from "../../assets/img/gb_pomaha.svg";
import TelefonWhite from "../../assets/img/telefonWhite.svg";
import Quotes from "../../assets/img/quotes.svg";
import Firma1 from "../../assets/img/firma1.png";
import Firma2 from "../../assets/img/firma2.png";
import Firma3 from "../../assets/img/firma3.png";
import NotinoLogo from "../../assets/img/notinoLogo.png";
import SlevomatLogo from "../../assets/img/slevomatLogo.png";
import SdgCenyImg from "../../assets/img/sdg_ceny2.png";
import SdgCenyImgMobile from "../../assets/img/sdg_ceny3.png";
import splatkyImg from "../../assets/img/splatky1.jpg";

import Press from "../pohrby/PressHp";
import LinkButtonV2 from "./LinkButtonV2";
import CallButtonV2 from "./CallButtonV2";
import RatingV2 from "./RatingV2";
import essoxLogo from "../../assets/img/essoxLogo.svg";
import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import cestaDomu from "../../assets/img/cestaDomu.webp";

const JumbotronInner = styled.div`
  background: #fff;
  background-image: linear-gradient(45deg, rgba(235,238,243,0.60) 0%, rgba(255,255,255,0.60) 15%, rgba(255,255,255,0.60) 79%, rgba(251,245,234,0.60) 100%);
  width: 100%;

  .splatky {
    max-width: 300px;
    margin: 0 auto;
    margin-left: 0;
    border-radius: 5px;
    margin-top: 40px;
    display: none;
    width: 100%;

    @media (max-width: 899px) {
      display: block;
    }
  }
/* 
  .splatky2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @media (max-width: 1070px) {
      max-width: 250px;
    }

    @media (max-width: 899px) {
      display: none;
    }
  } */

  .splatky3 {
    background: #eeece7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;

    @media (max-width: 899px) {
      display: none;
    }

    span {
      background: #a59c85;
      color: #fff;
      font-family: Visuelt-Medium;
      font-size: 14px;
      padding: 3px 5px;
      border-radius: 3px;
      margin-right: 15px;
    }

    img {
      height: 20px;
    }
  }

  .diteVSrdci {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    border: 2px solid #93403E;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;

    @media (max-width: 899px) {
      display: none;
    }

    img {
      width: 150px;
    }

    h3 {
      font-size: 18px;
      color: #623a39;
      font-family: Visuelt-Medium;
      margin-top: 8px;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      color: #846867;
      font-family: Visuelt-Regular;
    }

    a {
      color: #93403E;
      font-family: Visuelt-Regular;
    }
  }

  .hospice {
    border: 2px solid #FFB17F;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;

    img {
      width: 150px;
    }

    h3 {
      font-size: 18px;
      color: #0c1c31;
      font-family: Visuelt-Medium;
      margin-top: 8px;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      color: #616872;
      font-family: Visuelt-Regular;
    }

    a {
      color: #49576b;
      font-family: Visuelt-Regular;
    }
  }

  .wrap {
    max-width: 1150px;
    margin: 0 auto;
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 70px 0;

    @media (max-width: 899px) {
      display: block;
      padding: 50px 0;
      max-width: 700px;
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      padding: 30px 0;
      padding-bottom: 50px;
    }

    .left {
      max-width: 620px;
      order: 1;

      @media (max-width: 899px) {
        max-width: 100%;
      }

      h1 {
        font-family: NewKansas;
        font-size: 45px;
        color: #698199;
        color: #243A58;

        margin-top: 10px;

        @media (max-width: 700px) {
          font-size: 40px;
        }

        @media (max-width: 530px) {
          font-size: 35px;
        }

        @media (max-width: 430px) {
          font-size: 28px;
          margin-bottom: 15px;
          margin-top: 15px;
        }

        @media (max-width: 350px) {
          font-size: 25px;
        }
      }

      .mainHeading {
        color: #243A58;
        margin-bottom: 30px;
      }

      h2 {
        font-family: NewKansas;
        font-size: 40px;
        color: #698199;
        margin-bottom: 30px;

        @media (max-width: 1020px) {
          font-size: 35px;
        }

        @media (max-width: 899px) {
          font-size: 45px;
        }

        @media (max-width: 700px) {
          font-size: 40px;
        }

        @media (max-width: 600px) {
          font-size: 35px;
        }

        @media (max-width: 430px) {
          font-size: 32px;
        }
      }

      .desc {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #394B5C;
        line-height: 28px;
        margin-bottom: 35px;

        a {
          color: #394B5C;
        }

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .buttons {
        display: flex;
        margin-bottom: 35px;

        @media (max-width: 1005px) {
          display: block;
        }

        @media (max-width: 899px) {
          display: flex;
        }

        @media (max-width: 660px) {
          display: block;
        }
        
        div {
          margin-right: 20px;

          @media (max-width: 1005px) {
            margin-bottom: 15px;
          }

          @media (max-width: 660px) {
            margin-right: 0;
          }
        }
      }
    }

    .right {
      order: 2;
    }
  }

  .botCtaText {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #878787;
    line-height: 24px;
    margin-top: -30px;
    margin-bottom: 40px;
    display: block;
  }
  
  .nowrapReview {
    font-family: inherit;
    white-space: nowrap;

    @media (max-width: 350px) {
      white-space: break-spaces;
    }
  }

  .preCtaText {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #8b8b8b;
    line-height: 24px;
    margin-bottom: 20px; 
    display: block;
  }

  .zakazniciFirmy {
    
    span {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #606060;
      line-height: 24px;
      margin-bottom: 25px;
      display: block;
    }

    div {
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        display: block;
      }

      img {
        height: 35px;
        margin-right: 22px;

        @media (max-width: 600px) {
          display: inline-block;
          margin-bottom: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .rychleOdkazy {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #606060;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: -15px;
    display: block;

    a {
      color: #606060;
      text-decoration: underline;
    }
  }

  .sdg-ceny {
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 100;
    width: 200px;

    @media (max-width: 1450px) {
      width: 150px;
    }

    @media (max-width: 1350px) {
      display: none;
    }
  }

  .sdg-ceny-mobile {
    display: none;
    max-width: 400px;
    margin-top: 40px;

    

    @media (max-width: 1350px) {
      display: block;
    }

    @media (max-width: 1180px) {
      max-width: 300px;
      margin-top: 30px;
    }

    @media (max-width: 450px) {
      max-width: 100vw;
      overflow: hidden;
      margin-left: -30px;
      margin-top: 40px;

      img {
        width: calc(100% + 60px + 40px);
        max-width: none;
      }
    }
  }

  .givingTuesday {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    background: linear-gradient(135deg, #fff5eb 0%, #ffeedd 100%);
    padding: 15px;
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #122805;
    line-height: 24px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(237, 145, 54, 0.1);
    max-width: calc(100vw - 80px);
    border: 1px solid rgba(237, 145, 54, 0.2);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 25px rgba(237, 145, 54, 0.15);
    }

    .logo-container {
      background: white;
      border-radius: 10px;
      padding: 8px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 64px;
      box-shadow: 0 2px 8px rgba(237, 145, 54, 0.08);
    }

    .logo {
      width: 80px;
      height: auto;
      display: block;
    }

    .content {
      padding-right: 35px;
    }

    .closeButton {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      font-size: 24px;
      color: #ed9136;
      padding: 5px;
      line-height: 1;
      border: none;
      background: none;
      opacity: 0.7;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }

    p {
      position: relative;
      margin-bottom: 10px;
      color: #4A4A4A;

      &:first-of-type {
        font-size: 18px;
        font-family: Visuelt-Medium;
        margin-bottom: 10px;
      }

      span {
        color: #ed9136;
        font-family: Visuelt-Medium;
      }
    }

    .givingTuesdayDesc {
      font-size: 14px;
      line-height: 20px;
      color: #666;
      margin-bottom: 10px;
      max-width: 400px;
      padding-left: 0;
    }

    a {
      color: #ed9136;
      font-family: Visuelt-Medium;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s ease;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background: currentColor;
        opacity: 0;
        transition: all 0.2s ease;
      }

      &:hover {
        color: #d97b1e;
        
        &:after {
          opacity: 1;
          bottom: -1px;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 15px;
      
      .logo-container {
        margin-right: 0;
        margin-bottom: 15px;
        display: none;
      }

      .content {
        padding-right: 20px;
      }
      
      p:first-of-type {
        font-size: 16px;
      }
    }
  }
`;


const JumbotronV2 = ({
  h1,
  p,
  reviewDesc,
  jumboObrazek,
  jumboObrazekMobile,
  breadscrum,
  mapLocation,
  baby,
  hideSecondHeading,
  customCta,
  botCtaText,
  customPerex,
  noReview,
  hospice,
  preCtaText,
  noSplatky,
  showCompanies,
  animateH1,
  rychleOdkazy,
  customCtaText,
}) => {

  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const [showGivingTuesday, setShowGivingTuesday] = useState(true);
  const currentMonth = format(new Date(), 'LLLL', { locale: cs });

  return (
    <JumbotronInner>
      {!noSplatky &&
        <div className="splatky3">
          <span>Možnost platby na splátky</span>
          <img src={essoxLogo} alt="Pohřeb na splátky" />
        </div>
      }


      {showGivingTuesday && (
        <div className="givingTuesday">
          <button 
              className="closeButton" 
              onClick={() => setShowGivingTuesday(false)}
              aria-label="Zavřít"
            >
              ×
          </button>
          <div className="logo-container">
            <img src={cestaDomu} alt="Cesta domů" className="logo" />
          </div>
          <div className="content">
            <p>Po celý {currentMonth} <span>darujeme 10 % ze zisku</span> Cestě domů 🧡</p>
            <p className="givingTuesdayDesc">Cesta domů poskytuje odbornou péči umírajícím dospělým i dětem a jejich blízkým, kteří o ně doma pečují.</p>
            <Link to="https://www.darujme.cz/zivotazdokonce" target="_blank">
              Zjistit více o Cestě domů →
            </Link>
          </div>
        </div>
      )}

      <div className="wrap">

        <Link to="https://www.spolecenskaodpovednost.cz/jak-se-buduje-pohrebni-sluzba-21-stoleti/" target="_blank" className="sdg-ceny">
          <img src={SdgCenyImg} alt="SDG Ceny" />
        </Link>

        

        

        <div className="right rightJumbo">
          {isLess899 ?
            <Img className="obrazek jumboImage" alt={"Pohřebí služba " + mapLocation} fixed={jumboObrazekMobile} />
            :
            <Img className="obrazek jumboImage" alt={"Pohřebí služba " + mapLocation} fixed={jumboObrazek} />
          }
          {baby &&
            <div className="diteVSrdci">
              <img src={ditevsrdci} alt="Perinatální hospic Dítě v srdci" />
              <div>
                <h3>Užitečná stránka pro rodiče a poradna</h3>
                <p>Doporučujeme se obrátit na perinatální hospic <a href="https://ditevsrdci.cz/cz">Dítě v srdci</a>.</p>
              </div>
            </div>
          }
          

          {/* <img className="splatky splatky2" src={splatkyImg} alt="Pohřeb na splátky" /> */}

        </div>
        <div className="left leftJumbo">
          {breadscrum}
          
          <h1 data-aos={animateH1 && "new-animation"} className={hideSecondHeading && "mainHeading"}>{h1}</h1>
          {!hideSecondHeading && <h2>Empatická, osobní, důstojná.</h2>}

          {customPerex ? customPerex : <p className="desc" data-aos={animateH1 && "new-animation"} data-aos-delay="100">{p}</p>}
          
          {preCtaText && <span className="preCtaText">{preCtaText}</span>}

          <div className="buttons" data-aos={animateH1 && "new-animation"} data-aos-delay="200">
            {customCta ? customCta : <>
              <LinkButtonV2 to={baby ? "https://docs.google.com/forms/d/e/1FAIpQLScKgc40Rm7Y0sTrQ0zQjsYM4BI3wShwUAP_5ma7_KvEfh03WQ/viewform?usp=sf_link" : "/nabidka/dotaznik/"} arrow text={customCtaText ? customCtaText : "Získejte nabídku zdarma"} />
              <CallButtonV2 />
            </>}
          </div>

          {rychleOdkazy && <span className="rychleOdkazy">Pohřební služby: <Link to="/pohrebni-sluzba-praha/">Praha</Link>, <Link to="/pohrebni-sluzba-brno/">Brno</Link> a <Link to="/pobocky/">další</Link></span>}

          {botCtaText && <span className="botCtaText">{botCtaText}</span>}

          {!noReview && <RatingV2 reviewDesc={reviewDesc} />}
          {!noReview && 
            <Link to="https://www.spolecenskaodpovednost.cz/jak-se-buduje-pohrebni-sluzba-21-stoleti/" target="_blank" className="sdg-ceny-mobile">
              <img src={SdgCenyImgMobile} alt="SDG Ceny" />
            </Link>
          }


          

          {showCompanies &&
            <div className="zakazniciFirmy">
              <span>Své zaměstnance podporují mimo jiné i:</span>
              <div>
                <img src={NotinoLogo} alt="Firemní zákazník" />
                <img src={Firma2} alt="Firemní zákazník" />
                <img src={SlevomatLogo} alt="Firemní zákazník" />
                <img src={Firma1} alt="Firemní zákazník" />
                <img src={Firma3} alt="Firemní zákazník" />
              </div>
            </div>
          }

          {!noSplatky &&
            <img className="splatky" src={splatkyImg} alt="Pohřeb na splátky" />
          }

          {hospice &&
            <div className="hospice">
              <img src={gbPomaha} alt="Perinatální hospic Dítě v srdci" />
              <div>
                <h3>Pomáháme rodinám při ztrátě blízkého v paliativním zařízení</h3>
                <p>Veškeré výjezdy do hospiců, LDN a jiných paliativních zařízení zákazníkům naší pohřební služby oproti jiným pohřebním ústavům neúčtujeme. Platí na <Link to="/pohrebni-sluzba-praha/">pohřební službu Praha</Link> i ostatní města.</p>
              </div>
            </div>
          }
        </div>
      </div>

      
    </JumbotronInner>
  );
}

export default JumbotronV2;


